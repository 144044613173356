import { useFormContext } from "remix-validated-form";

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import {
  Alert,
  AlertDetailItem,
  AlertDetailsList,
  AlertTitle,
} from "~/components/ui/alert";

type FormErrorsProps = {
  hideFieldName?: {
    [key: string]: boolean;
  };
};

export const FormErrors = ({ hideFieldName }: FormErrorsProps) => {
  const state = useFormContext();
  if (!state) throw new Error("FormErrors must be used within a FormContext");

  if (state.hasBeenSubmitted && !state.isSubmitting) {
    const errors = Object.entries(state.fieldErrors);
    if (!errors.length) return null;

    return (
      <Alert className="my-6" variant="error">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <AlertTitle>
          There were {errors.length} errors with your submission
        </AlertTitle>
        <AlertDetailsList>
          {errors.map(([field, message], i) => (
            <AlertDetailItem
              key={i}
              name={field}
              hideName={
                hideFieldName && Object.keys(hideFieldName).includes(field)
                  ? hideFieldName[field]
                  : false
              }
            >
              {message}
            </AlertDetailItem>
          ))}
        </AlertDetailsList>
      </Alert>
    );
  }
  return null;
};
